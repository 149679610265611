import * as React from "react"
import { Heading, Text, Button, Link, Badge, Box, Divider, Flex, UnorderedList, ListItem } from "@chakra-ui/react"
import Layout from "../components/Layout"
import Jumbo from "../components/Jumbo"
import ShepherdsContainer from "../components/ShepherdsContainer"
import golf from "../images/bowling.jpg"

const Bowling = () => {
  return (
    <Layout>
      <Jumbo imageUrl={golf}>
        <Heading size="2xl">1st Annual Tuskawilla Shepherds BOWL-A-THON</Heading>
        <Text as="i">
          Dedicated to feeding hungry kids in Seminole County, FL schools
        </Text>
      </Jumbo>
      <ShepherdsContainer>
        <Heading size="lg">Sunday, September 15th at Aloma Bowl</Heading>
        <Text>Join us for our 1st annual bowling fundraiser to
          support Tuskawilla Shepherds and provide
          food to Seminole County school food pantries
          for the 2024-2025 school year.
          Rally your friends, family, or teambuild
          with your co-workers and form a team
          to enjoy two hours of bowling,
          fun, fellowship, and food
          while supporting a great cause.</Text>
        <Text>
          <Link href="https://ezregister.com/events/40818/">
            <Button as="Link" mr={2} colorScheme="red">
              Sign up
            </Button>
          </Link>
          to reserve your lane now!
        </Text>
        <Text>Check-in at 2:30, bowling from 3:00-5:00</Text>
        <Flex>
          <Box as="address">
            <Text>Aloma Bowl</Text>
            <Divider />
            <Text>2530 Aloma Avenue</Text>
            <Divider />
            <Text>Winter Park, FL 32792</Text>
          </Box>
        </Flex>
        <Text>All registration levels include two hours of bowling, bowling shoe rental, two slices of pizza, and unlimited soda.</Text>
        <Heading mt={4} size="md">
          Team Registration (Up to 5 bowlers per team):
        </Heading>
        <UnorderedList>
          <ListItem mb={4}><Badge fontSize="sm" colorScheme="yellow">Gold:</Badge> $475 - Everything listed above plus:
            <UnorderedList>
              <ListItem>Reserved lane with team name/company logo recognition</ListItem>
              <ListItem>Logo and/or team photo posted on Tuskawilla Shepherds event website</ListItem>
              <ListItem>Tagged in two posts on Tuskawilla Shepherds Facebook page</ListItem>
              <ListItem>Two beer tickets per bowler</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem mb={4}><Badge fontSize="sm">Silver:</Badge> $325 - Everything listed above plus:
            <UnorderedList>
              <ListItem>Reserved lane with team name/company logo recognition</ListItem>
              <ListItem>Logo and/or team photo posted on Tuskawilla Shepherds event website</ListItem>
              <ListItem>Tagged in one post on Tuskawilla Shepherds Facebook page</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem mb={4}><Badge fontSize="sm" colorScheme="orange">Bronze:</Badge> $225 - Everything listed above plus:
            <UnorderedList>
              <ListItem>Reserved Lane with team name/company logo recognition</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Heading mt={4} size="md">
          Individual Registration:
        </Heading>
        <UnorderedList>
          <ListItem>Individual: $35
            <UnorderedList>
              <ListItem>Assigned lane with other individuals</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </ShepherdsContainer>
    </Layout>
  )
}

export default Bowling

export const Head = () => <title>Bowling</title>
