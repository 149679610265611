import * as React from "react"
import { Box } from "@chakra-ui/react"
import ShepherdsContainer from "./ShepherdsContainer"

const Jumbo = ({ children, imageUrl }) => {
  return (
    <Box
      backgroundImage={`url(${imageUrl})`}
      backgroundPosition="center"
      backgroundSize="cover"
      width="calc(100vw - (100vw - 100%))"
      py="20"
      textShadow="0 0 5px black"
      color="white"
      mb={10}
    >
      <ShepherdsContainer>{children}</ShepherdsContainer>
    </Box>
  )
}

export default Jumbo
